import React from 'react'

interface IProps {
  image: string
  text: string
}

const HeroTitle = (props: IProps) => {
  const { image, text } = props
  return (
    <div className="mt-16">
      <section
        className="relative bg-fixed bg-cover bg-center bg-no-repeat py-16 md:py-48 after:absolute after:inset-0 "
        style={{
          backgroundImage: `linear-gradient(270deg, rgba(255, 255, 255, 0), rgba(0, 0, 0, .7)), url(${image})`
        }}
      >
        <div className=" z-10">
          <h1 className="text-center text-lg md:text-[2.8rem] font-medium capitalize font-sans text-primary-950 text-shadow-lg shadow-primary-300">
            <span className="text-secondary-500 bg-white py-5 px-10 rounded-md">
              {text}
            </span>
          </h1>
        </div>
        <div className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden "></div>
      </section>
    </div>
  )
}

export default HeroTitle
