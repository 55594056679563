import React from 'react'
import Link from 'next/link'
import { RssIcon } from '@heroicons/react/24/solid'

interface IProps {
  id?: number
  icon: string
  link: string
  label?: string
}

const getIcon = (text: string) => {
  return (
    <svg
      fill="currentColor"
      className="icon h-6 w-6 fill-primary-950 group-hover:fill-secondary-400"
    >
      <use xlinkHref={`/icons.svg#icon-${text}`} />
    </svg>
  )
}

const SocialIcons = ({ id, icon, link, label }: IProps) => {
  let display = <></>
  switch (icon) {
    case 'rss':
      display = (
        <RssIcon className="icon h-7 w-7 fill-primary-300 group-hover:fill-orange-500" />
      )
      break

    default:
      display = getIcon(icon)
      break
  }

  return (
    <Link
      href={link}
      key={id}
      target="_blank"
      rel="noopener noreferrer"
      className="group mx-2 cursor-pointer flex place-items-center"
      aria-label={label ? label : `Sígueme en ${icon}`}
    >
      {display}
    </Link>
  )
}

export default SocialIcons
