import React from 'react'
import Link from 'next/link'

import { SocialIcons as Icons } from '@/components/common'

interface IProps {
  id: string
  linkedin: string
  github: string
  twitter: string
}

const Me = ({ id, linkedin, github, twitter }: IProps) => {
  const lnkText = 'linkedin'
  const xText = 'twitter'
  const ghText = 'github'

  return (
    <div id={id} className="border-l-4 border-secondary-300 pl-4">
      <p className="py-2 first-letter:font-logo first-letter:text-4xl first-letter:text-secondary-500">
        Soy desarrollador desde poco más de 20 años. Me gusta colaborar con la
        creación de equipos, organización del equipo de desarrollo y mejora de
        procesos.
      </p>
      <p>
        Si tienes una PyMe y deseas mejorar tu proceso de desarrollo de
        software, puedes <Link href="/contacto">mandarme un mensaje</Link> y con
        gusto platicamos sobre cómo podemos colaborar.
      </p>
      <ul>
        <li className="grid grid-flow-col bg-blue-200 rounded-lg my-4 py-4">
          <Icons key={lnkText} icon={lnkText} link={linkedin} />
          <p className="px-4">
            Para saber más sobre mi experiencia y en que tecnologías tengo
            experiencia, puedes consultar mi{' '}
            <Link href={linkedin}>LinkedIn</Link>
          </p>
        </li>
        <li className="grid grid-flow-col bg-white rounded-lg my-4 py-4">
          <Icons key={xText} icon={xText} link={twitter} />
          <p className="px-4">
            También escribo cosas en <Link href={twitter}>X</Link> (antes
            <Link href={twitter}> Twitter</Link>) y re-publico lo que me parece
            interesante aunque no es muy seguido.
          </p>
        </li>
        <li className="grid grid-flow-col bg-secondary-200 rounded-lg my-4 py-4">
          <Icons key={ghText} icon={ghText} link={linkedin} />
          <p className="px-4">
            Escribo algo de código y lo mantengo en{' '}
            <Link href={github} target="_blank">
              github
            </Link>
            . Aunque tengo varios proyectos públicos, en general la mayor
            aportación está en privado.
          </p>
        </li>
      </ul>
    </div>
  )
}

export default Me
