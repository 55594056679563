'use client'
import React, { useState, useEffect } from 'react'

import Link from 'next/link'
import { Bars3Icon } from '@heroicons/react/24/solid'
import { usePathname } from 'next/navigation'

import { addMobileMenuToggle, removeMenuActive } from '@/lib/index'
import { default as IMenu } from './types'
import { OpenFont } from '@/components/common'
import { ItemMenu } from './ItemMenu'

interface IProps {
  children?: React.ReactNode
  menuItems: IMenu[]
  title: string
}

const Menu = (props: IProps) => {
  const { children, menuItems, title } = props
  const pathname = usePathname()
  const [open, setOpen] = useState<boolean>(false)

  const isActive = (path: string) => {
    return path === pathname
  }

  const handleSelectItem = () => {
    setOpen(false)
  }

  useEffect(() => {
    addMobileMenuToggle()
    return () => {
      removeMenuActive()
    }
  }, [])

  const items = menuItems.map((element) => {
    return (
      <ItemMenu
        key={element.id}
        url={`${element.link}`}
        selected={isActive(element.link)}
        onClick={handleSelectItem}
        text={element.name}
      />
    )
  })

  const active = open ? 'block' : 'hidden'

  return (
    <header>
      <nav className="fixed top-0 z-20 w-full backdrop-blur transition-colors border-b border-primary-400 border-opacity-10">
        {children}
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto px-4 py-2 space-x-1 md:hidden">
          <Bars3Icon
            onClick={() => {
              setOpen(!open)
            }}
            className="text-neutral-800 bg-primary-50 bg-opacity-70 mx-3 my-3 h-10 w-10 cursor-pointer rounded border-[1px] border-solid border-neutral-800 p-2 text-sm "
          />
        </div>
        <div
          className={`bg-primary-50 bg-opacity-65 md:bg-opacity-15 md:backdrop-blur md:flex md:flex-grow md:flex-row md:justify-end md:space-x-1 flex flex-col p-4 font-medium md:mt-0 ${active}`}
        >
          <div
            className={`container t-shadow mx-auto font-bold flex items-center justify-between uppercase text-base md:text-[1.6em] tracking-[3px] ${OpenFont.variable} font-sans  md:border-none`}
          >
            <Link href={'/#home'} className="font-logo capitalize">
              {title}
            </Link>
          </div>
          <div className="flex place-content-end w-1/2 gap-2">{items}</div>
          {/* <DarkMode /> */}
        </div>
      </nav>
    </header>
  )
}

export default Menu
